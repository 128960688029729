import { Component, OnInit, Injector, OnDestroy, Input } from '@angular/core';
import * as _ from 'lodash';
import { CustomersServiceProxy, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import { FileDownloadService } from '@shared/utils/file-download.service';

@Component({
    selector: 'app-widget-reports',
    templateUrl: './widget-reports.component.html',
    styleUrls: ['./widget-reports.component.css']
})
export class WidgetReportsComponent extends WidgetComponentBaseComponent implements OnInit, OnDestroy {

    constructor(
        injector: Injector,
        private _tenantDashboardServiceProxy: TenantDashboardServiceProxy,
        private _customersServiceProxy: CustomersServiceProxy,
        private _fileDownloadService: FileDownloadService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.runDelayed(() => {
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    public getReport() {
        this._customersServiceProxy.getMsaReportExcel()
            .subscribe(result => {
                this._fileDownloadService.downloadTempFile(result);
            });
    }
}
