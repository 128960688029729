import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { SignaturePad } from 'angular2-signaturepad';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'userSignatureModal',
  templateUrl: './user-signature-modal.component.html',
  styleUrls: ['./user-signature-modal.component.less']
})
export class UserSignatureModalComponent extends AppComponentBase {

  @ViewChild('addSignatureModal', { static: true }) modal: ModalDirective;
  @ViewChild('signaturePad', { static: true }) signaturePad: SignaturePad;
  saving: boolean = false;

  private signature: string = null;

  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 750,
    'canvasHeight': 150,
  };

  constructor(
    injector: Injector,
    private _profileService: ProfileServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getSignature();
  }

  show(): void {
    this.modal.show();
  }

  async save(): Promise<void> {
    try {
      this.saving = true;
      const url = this.signaturePad.toDataURL();
      const file = this.dataURLtoFile(url, "signature.png");
      await this._profileService.updateUserSignature({ data: file, fileName: "signature.png" }).toPromise()
      this.signature = url;
      this.saving = false;
      this.notify.success("Signature Saved");
      this.close();
    } catch (error) {
      console.log("error saving signature");
      this.notify.error("Unable to save signature");
    }
  }

  clear(): void {
    this.signaturePad.clear();
  }

  close(): void {
    this.modal.hide();
    this.signaturePad.clear();
    this.signaturePad.fromDataURL(this.signature);
  }

  onShown(): void {
    // do something when modal is shown.
  }

  private dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  private getSignature(): void {
    console.log("getting signature");
    this._profileService.getUserSignature().subscribe(result => {
      if (result != null) {
        this.signature = 'data:image/jpeg;base64,' + result;
        this.signaturePad.fromDataURL(this.signature);
      }
    });
  }

}
