import { Injectable } from '@angular/core';
import { CreateOrEditJobDto, ManifestDto } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ManifestPanelService {

    private showManifestPanel = new BehaviorSubject(false);
    sharedShowManifestPanel = this.showManifestPanel.asObservable();

    private manifests = new BehaviorSubject<IManifestDtoWrapper[]>([]);
    sharedManifests = this.manifests.asObservable();

    private manifestsUpdated = new BehaviorSubject(true);
    sharedManifestsUpdated = this.manifestsUpdated.asObservable();

    private currentManifestsRemoved = new BehaviorSubject<ManifestDto>(null);
    sharedCurrentManifestsRemoved = this.currentManifestsRemoved.asObservable();

    private currentManifests = new BehaviorSubject<IManifestDtoWrapper[]>([]);
    sharedCurrentManifests = this.currentManifests.asObservable();

    private oldManifests = new BehaviorSubject<IManifestDtoWrapper[]>([]);
    sharedOldManifests = this.oldManifests.asObservable();

    constructor() { }

    triggerManifestsUpdated() {
        let temp = this.manifestsUpdated.value;
        this.manifestsUpdated.next(!temp);
    }

    setShowManifestPanel(show: boolean) {
        this.showManifestPanel.next(show)
    }

    refreshManifestListings() {
        this.currentManifests.next(this.currentManifests.value);
        this.oldManifests.next(this.oldManifests.value);
    }

    setManifests(manifests: ManifestDto[]) {
        this.manifests.next(manifests.map(x => this.mapToWrappedManifest(x)));
    }

    removeManifest(id: number, isCurrentManifest: boolean): ManifestDto {
        let temp = this.manifests.value;
        let index = temp.findIndex(x => x.manifest.id == id);
        if (index == -1) {
            return;
        }
        let removedManifest = temp.splice(index, 1)[0];
        this.manifests.next(temp);
        this.triggerManifestsUpdated();

        if (isCurrentManifest) {
            this.currentManifestsRemoved.next(removedManifest.manifest);
        }
    }

    setCurrentManifests(manifests: ManifestDto[]) {
        let wrappedManifests = manifests.map(x => this.mapToWrappedManifest(x));
        this.currentManifests.next(wrappedManifests);
    }

    setOldManifests(manifests: ManifestDto[]) {
        let wrappedManifests = manifests.map(x => {
            let fsoDate = null;
            if (x.fsoDate) {
                fsoDate = moment(x.fsoDate).format('L');
            }
            return {
                manifest: x,
                fsoDate: fsoDate
            }
        });
        this.oldManifests.next(wrappedManifests);
    }

    addCurrentManifest(manifest: ManifestDto) {
        let temp = this.manifests.value;
        temp.push(this.mapToWrappedManifest(manifest));
        this.manifests.next(temp);
        this.triggerManifestsUpdated();
    }

    private mapToWrappedManifest(x) {
        let fsoDate = null;
        if (x.fsoDate) {
            fsoDate = moment(x.fsoDate).format('L');
        }
        return {
            manifest: x,
            fsoDate: fsoDate
        }
    }

    resetFSO_manifests(fsoId: number) {
        let currentManifests = this.currentManifests.value;
        let oldManifests = this.oldManifests.value;
        let stillCurrentManifests = currentManifests.filter(x => x.manifest.fsoId != fsoId);
        let newOldManifests = currentManifests.filter(x => x.manifest.fsoId == fsoId);
        this.currentManifests.next(stillCurrentManifests);
        this.oldManifests.next(oldManifests.concat(newOldManifests));
    }

}

export interface IManifestDtoWrapper {
    manifest: ManifestDto,
    fsoDate: string
}
