import { Component, OnInit, Injector, OnDestroy, Input } from '@angular/core';
import * as _ from 'lodash';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBaseComponent } from '../widget-component-base';
import { WeatherLocation } from './models';
import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WeatherService } from './weather.service';


@Component({
  selector: 'app-widget-weather',
  templateUrl: './widget-weather.component.html',
  styleUrls: ['./widget-weather.component.css']
})
export class WidgetWeatherComponent extends WidgetComponentBaseComponent implements OnInit, OnDestroy {
    private loadWeather() {
        (function (d, s, id) {
            if (d.getElementById(id)) {
                if ((window as any).__TOMORROW__) {
                    (window as any).__TOMORROW__.renderWidget();
                }
                return;
            }
            const fjs: any = d.getElementsByTagName(s)[0];
            const js: any = d.createElement(s);
            js.id = id;
            js.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";

            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'tomorrow-sdk');
    }

    constructor(
        injector: Injector,
        private _tenantDashboardServiceProxy: TenantDashboardServiceProxy,
        private weatherService: WeatherService
    ) {
      super(injector);
    }

    ngOnInit(): void {
        this.runDelayed(() => {
            // this.loadWeather();
        });
    }

    ngAfterViewInit(): void {
        this.loadWeather();
    }

    ngOnDestroy(): void {
    }
}

