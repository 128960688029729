import { Injectable } from '@angular/core';
import { DocumentsContainerComponent } from '@app/main/jobs/documents/documents-container.component';
import { FsoContainerComponent } from '@app/main/jobs/fso/fso-container.component';
import { CreateOrEditJobDto, ManifestDto } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class JobResourceSharingService {
    jobType: string;
    fsoContainer: FsoContainerComponent = null;
    documentContainer: DocumentsContainerComponent = null;

    private job = new BehaviorSubject<CreateOrEditJobDto>(new CreateOrEditJobDto());
    sharedJob = this.job.asObservable();

    constructor() { }

    setJob(job: CreateOrEditJobDto) {
        this.job.next(job);
    }
}

export interface IManifestDtoWrapper {
    manifest: ManifestDto,
    fsoDate: string
}
