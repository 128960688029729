// "Production" enabled environment

export const environment = {
    production: true,
    clarityId: "n5dwl5006w",
    hmr: false,
    appConfig: 'appconfig.prod.json',
    apis: {
        weather: {
            url: 'https://api.weather.gov',
            userAgent: '(app.costeraenv.com, info@costeraenv.com)'
        },
    }
};
