export class PhoneNumberFormatHelper {
  constructor() { }

  public static phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public static phoneMaskWithAreaCode = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public static phoneMaskWithExtention = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'x', /\d/, /\d/, /\d/, /\d/];


  public static formatPhoneNumber(phoneNumberString: string, extention?: string): string {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    var result = phoneNumberString;
    if (match) {
      result = '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    if (extention) {
      result = `${result} x${extention}`;
    }

    return result;
  }

  public static splitPhoneNumber(phone: string): ISplitNumber {
    if (phone == null || phone.trim().length == 0) {
      return { number: "", ext: "" };
    }
    const parts = phone.split('x');
    var number = parts[0];
    var ext = parts.length > 1 ? parts[1] : null;

    return { number, ext };
  }

  public static combineSplitNumber(split: ISplitNumber): string {
    return split.ext == null || split.ext.trim().length == 0 ? (split.number).replace(/\D/g, '') : `${(split.number).replace(/\D/g, '')}x${(split.ext).replace(/\D/g, '')}`;
  }

}

export interface ISplitNumber {
  number: string;
  ext?: string;
}