import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { WeatherLocation, WeatherLocationForecast } from './models';

export const WEATHER_GOV_API_BASE_URL = new InjectionToken<string>('WEATHER_GOV_API_BASE_URL');
interface WeatherGovPoint {
    id: string;
    type: string;
    geometry: {
        type: string;
        coordinates: []
    },
    properties: {
        id: string;
        type: string;
        cwa: string;
        forecastOffice: string;
        gridId: string;
        gridX: number;
        gridY: number;
        forecast: string;
        forecastHourly: string;
        forecastGridData: string;
        observationStations: string;
        forecastZone: string;
        county: string;
        fireWeatherZone: string;
        timeZone: string;
        radarStation: string;
        relativeLocation: {
            type: string;
            geometry: {
                type: string;
                coordinates: []
            },
            properties: {
                city: string;
                state: string;
                distance: {
                    unitCode: string;
                    value: number;
                },
                bearing: {
                    unitCode: string;
                    value: number;
                }
            }
        }
    }
}

interface WeatherGovForecastPeriod {
    number: number;
    name: string;
    startTime: string;
    endTime: string;
    isDaytime: boolean;
    temperature: number;
    temperatureUnit: string;
    temperatureTrend?: string,
    probabilityOfPrecipitation: {
        unitCode: string;
        value?: number
    },
    dewpoint: {
        unitCode: string;
        value?: number
    },
    relativeHumidity?: {
        unitCode: string;
        value?: number
    },
    windSpeed: string;
    windDirection: string;
    icon: string;
    shortForecast: string;
    detailedForecast: string;
}

interface ForecastResultLocation {
    region: string;
    latitude: number;
    longitude: number;
    elevation: number
    wfo: string;
    timezone: string;
    areaDescription: string;
    radar: string;
    zone: string;
    county: string;
    firezone: string;
    metar: string;
}

interface ForecastResultTime {
    layoutKey: string;
    startPeriodName: string[];
    startValidTime: string[];
    tempLabel: string[];
}

interface ForecastResultData {
    temperature: string[];
    pop: string[];
    weather: string[];
    iconLink: string[];
    hazard: string[];
    hazardUrl: string[];
    text: string[];
}

interface ForecastResultCurrentObservations {
    Altimeter: number;
    Date: string;
    Dewp: number;
    Gust: string;
    Relh: number;
    SLP: number;
    Temp: number;
    Visibility: number;
    Weather: string;
    Weatherimage: string;
    WindChill: string;
    Windd: number;
    Winds: number;
    elev: number;
    id: string;
    latitude: number;
    longitude: number;
    name: string;
    state: string;
    timezone: string;
}

interface ForecastResult {
    operationalMode: string;
    srsName: string;
    creationDate: string;
    creationDateLocal: string;
    currentobservation: ForecastResultCurrentObservations;
    productionCenter: string;
    credit: string;
    moreInformation: string;
    location: ForecastResultLocation;
    time: ForecastResultTime;
    data: ForecastResultData;
}


interface WeatherGovForecast {
    id: string;
    type: string;
    geometry: {
        type: string;
        coordinates: []
    };
    properties: {
        updated: string;
        units: string;
        forecastGenerator: string;
        generatedAt: string;
        updateTime: string;
        validTimes: string;
        elevation: {
            unitCode: string;
            value: number;
        },
        periods: WeatherGovForecastPeriod[]
    }
}


interface MarineForecastLocation {
    region: string;
    latitude: string;
    longitude: string;
    elevation: string;
    wfo: string;
    timezone: string;
    areaDescription: string;
    radar: string;
    zone: string;
    county: string;
    firezone: string;
    metar: string;
}

interface MarineForecastTime {
    layoutKey: string;
    startPeriodName: string[];
    startValidTime: string[];
    tempLabel: string[];
}

interface MarineForecastData {
    temperature: (string | null)[];
    pop: (number | null)[];
    weather: string[];
    iconLink: string[];
    hazard: string[];
    hazardUrl: string[];
    text: string[];
}

interface MarineForecastObservation {
    id: string;
    name: string;
    elev: string;
    latitude: string;
    longitude: string;
    Date: string;
    datetime: string;
    Temp: string;
    AirTemp: string;
    WaterTemp: string;
    Dewp: string;
    Winds: string;
    Windd: string;
    Gust: string;
    Weather: string;
    WaveHeight: string;
    Visibility: string;
    Pressure: string;
    timezone: string;
    state: string;
    DomWavePeriod: string;
}

@Injectable({
    providedIn: 'root'
})
export class WeatherService {
    private readonly baseUrl: string = "https://api.weather.gov";
    private readonly userAgent: string = "(portal.dcorllc.com, noreply@dcorllc.com)";
    private readonly client: HttpClient;


    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            return of(result as T);
        };
    }

    private optionsHeaders(): any {
        return new HttpHeaders({
            "User-Agent": this.userAgent,
            "Accept": "application/json"
        });
    }

    //#region Ctor
    constructor(
        private http: HttpClient,
        handler: HttpBackend,
        @Inject(WEATHER_GOV_API_BASE_URL) baseUrl?: string
    ) {
        this.client = new HttpClient(handler);

        if (!_.isNil(baseUrl)) { this.baseUrl = baseUrl; }

        if (!_.isNil(environment.apis.weather.userAgent))
            this.userAgent = environment.apis.weather.userAgent;
    }
    //#endregion

    validateCoordinates(latitude: number, longitude: number) {
        if (!_.isNumber(longitude) || longitude < -180 || longitude > 180)
            throw new Error('Latitude must be a number between -180 and +180');
        if (!_.isNumber(latitude) || latitude < -90 || latitude > 90)
            throw new Error('Latitude must be a number between -90 and +90');
    }

    getLocationInformation(latitude: number, longitude: number, useMarine: boolean = false): Observable<WeatherLocation | undefined> {
        this.validateCoordinates(latitude, longitude);

        const url = `${this.baseUrl}/points/${latitude},${longitude}`;

        return this.client.get<WeatherGovPoint>(url, { observe: "body", headers: this.optionsHeaders() })
            .pipe(
                map(result => {
                    if (_.isNil(result))
                        return undefined;

                    return {
                        city: result.properties?.relativeLocation?.properties?.city,
                        state: result.properties?.relativeLocation?.properties?.state,
                        coordinates: {
                            latitude: latitude,
                            longitude: longitude
                        },
                        forecastApiLink: result.properties?.forecast,
                        forecastSiteLink: `https://${useMarine ? 'marine' : 'forecast'}.weather.gov/MapClick.php?lat=${latitude}&lon=${longitude}`
                    } as WeatherLocation;
                }),
                catchError(this.handleError<WeatherLocation>('getLocationInformation'))
            );
    }

    getForecast(link?: string): Observable<ForecastResult | undefined> {
        if (_.isNil(link) || _.isEmpty(link))
            throw new Error("A valid link must be provided");

        link += "&unit=0&lg=en&FcstType=json";

        return this.client.get<ForecastResult>(link, { headers: this.optionsHeaders() })
            .pipe(
                catchError(this.handleError<ForecastResult>('getLocationForecast'))
            );
    }
}
