import { Component, Injector, Input } from '@angular/core';
import {  Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'sub-header-stacked',
    templateUrl: './sub-header-stacked.component.html',
    styleUrls: ['./sub-header-stacked.component.less']
})
export class SubHeaderStackedComponent extends AppComponentBase {
    @Input() title = '';
    @Input() description = '';

    constructor(
        private _router: Router,
        injector: Injector
    ) {
        super(injector);
    }
}
