export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Costera'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly jobStatuses = {
        InProcess: 'In Process',
        Completed: 'Completed',
        Reconciled: "Reconciled",
        Billed: "Billed",
        InReview: "In Review",
        Verified: "Verified",
    };


    static readonly jobType = {
        Job: 'Job',
        Quote: 'Quote',
        Reconciliation: "Reconciliation",
        Invoice: "Invoice",
    };

    static readonly jobTypes = [
        { name: "Job", value: "Job"},
        { name: "Quote", value: "Quote"},
        { name: "Reconciliation", value: "Reconciliation"},
        { name: "Invoice", value: "Invoice"},
    ];

    static readonly wasteTypeHazType = {
        Hazardous: 'Hazardous',
        NonHazardous: 'Non-Hazardous'
    };

    static readonly TaskServiceRelationshipType = {
        None: "None",
        All: "All",
        Filtered: "Filtered"
    };

    static readonly TaskManifestStatus = {
        Incomplete: "Incomplete",
        Complete: "Complete",
        Printed: "Printed",
        Modified: "Modified",
        DoNotPrint: "Do Not Print"
    };

    static readonly WasteHazardousType = {
        Hazardous: "Hazardous",
        NonHazardous: "Non-Hazardous"
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '9.1.0-rc.1';
}
