import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';
import { ManifestPanelService } from '@app/shared/common/manifestPanelService/manifestPanelService';
import { JobResourceSharingService } from '@app/shared/common/jobResourceSharingService/jobResourceSharingService';

@NgModule({
    providers: [
        JobResourceSharingService,
        ManifestPanelService,
        ApiServiceProxies.PdfGenerationServiceProxy,
        ApiServiceProxies.WeightVolumeUnitsServiceProxy,
        ApiServiceProxies.ManifestsServiceProxy,
        ApiServiceProxies.DotPsnsServiceProxy,
        ApiServiceProxies.ContainerTypesServiceProxy,
        ApiServiceProxies.EpaWasteCodesServiceProxy,
        ApiServiceProxies.StateWasteCodeServiceProxy,
        ApiServiceProxies.TaskServicesServiceProxy,
        ApiServiceProxies.ApiServiceProxy,
        ApiServiceProxies.FileDatasServiceProxy,
        ApiServiceProxies.DocumentsServiceProxy,
        ApiServiceProxies.JobStatusesServiceProxy,
        ApiServiceProxies.WasteTypesServiceProxy,
        ApiServiceProxies.TsdfsServiceProxy,
        ApiServiceProxies.VendorsServiceProxy,
        ApiServiceProxies.TaskTypesServiceProxy,
        ApiServiceProxies.UnitsServiceProxy,
        ApiServiceProxies.TaskScopesServiceProxy,
        ApiServiceProxies.TaskGroupsServiceProxy,
        ApiServiceProxies.FieldServiceOrdersServiceProxy,
        ApiServiceProxies.JobsServiceProxy,
        ApiServiceProxies.SitesServiceProxy,
        ApiServiceProxies.GeneratorsServiceProxy,
        ApiServiceProxies.CustomersServiceProxy,
        ApiServiceProxies.TaskItemsServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.VendorInvoicesServiceProxy,
        ApiServiceProxies.StatesServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ]
})
export class ServiceProxyModule { }
