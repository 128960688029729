import { Component, OnInit, Injector, OnDestroy, Input, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WeatherLocation, WeatherLocationForecast } from '../models';
import { WeatherService } from '../weather.service';


@Component({
  selector: 'app-weather-panel',
  templateUrl: './weather-panel.component.html',
  styleUrls: ['./weather-panel.component.css']
})
export class WeatherPanelComponent implements OnInit {
    @Input("latitude") latitude!: number;
    @Input("longitude") longitude!: number;
    @Input("description") description!: string;
    @Input("useMarine") useMarine: boolean = false;

    locationLoaded = false;
    forecastLoaded = false;

    location?: WeatherLocation;
    temperatureColor?: string;

    private getCurrentForecast(location: WeatherLocation): void {
        if (_.isNil(location))
            return;

        this.forecastLoaded = false;

        this.weatherService.getForecast(this.location?.forecastSiteLink)
            .subscribe(forecast => {
                if (!_.isNil(this.location) && !_.isNil(forecast) && !_.isNil(forecast.data)) {
                    this.location.currentForecast = {
                        label: forecast.time.startPeriodName[0],
                        icon: forecast.data.iconLink[0],
                        detailedForecast: forecast.data.text[0],
                        hazard: forecast.data.hazard[0],
                        temperature: {
                            value: _.toNumber(forecast.data.temperature[0]),
                            label: forecast.time.tempLabel[0],
                            color: this.getTemperatureColor(_.toNumber(forecast.data.temperature[0]))
                        }
                    };

                    // Grabbing the next 2 forecasts
                    this.location.forecasts = [];
                    for (let i = 1; i < 3; i++) {
                        this.location.forecasts.push({
                            label: forecast.time.startPeriodName[i],
                            icon: forecast.data.iconLink[i],
                            detailedForecast: forecast.data.text[i],
                            hazard: forecast.data.hazard[i],
                            temperature: {
                                value: _.toNumber(forecast.data.temperature[i]),
                                label: forecast.time.tempLabel[i],
                                color: this.getTemperatureColor(_.toNumber(forecast.data.temperature[i]))
                            }
                        } as WeatherLocationForecast)
                    }
                }
                this.forecastLoaded = true;
            });
    }

    private getTemperatureColor(temperature: number): string | undefined {
        if (_.isNumber(temperature)) {
            if (temperature >= 100)
                return '#d63384';
            else if (temperature >= 90 && temperature < 100)
                return '#ef4444';
            else if (temperature >= 80 && temperature < 90)
                return '#fd7e14';
            else if (temperature >= 70 && temperature < 80)
                return '#ffc107';
            else if (temperature >= 60 && temperature < 70)
                return '#198754';
            else if (temperature >= 50 && temperature < 60)
                return '#20c997';
            else if (temperature >= 40 && temperature < 50)
                return '#0dcaf0';
            else if (temperature >= 30 && temperature < 40)
                return '#0d6efd';
            else if (temperature >= 20 && temperature < 30)
                return '#6f42c1';
            else
                return 'text-purple-500';
        }

        return undefined;
    }

    private getLocation(): Observable<void> {
        this.weatherService.validateCoordinates(this.latitude, this.longitude);

        return this.weatherService
            .getLocationInformation(this.latitude, this.longitude, this.useMarine)
            .pipe(
                switchMap(location => {
                    this.locationLoaded = true;
                    if (!_.isNil(location)) {
                        this.location = location;
                        this.getCurrentForecast(location);
                    }
                    return EMPTY;
                })
            );
    }

    //#region Ctor
    constructor(private readonly weatherService: WeatherService) {
    }
    //#endregion

    ngOnInit(): void {
        this.getLocation().subscribe();
    }

    refresh(): void {
        this.forecastLoaded = false;

        setTimeout(() => {
            this.getCurrentForecast(this.location!);
        }, 1000);
    }
}
