import { QuillConfig } from 'ngx-quill';
import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter';

const fontsList = ['Sans-Serif', 'Serif', 'Monospace'];

function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}

function generateFontStyles(fonts: string[]): void {
    let fs = "";
    fonts.forEach((font) => {
        const fontName = getFontName(font);
        fs += `
        .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="${fontName}"]::before,
        .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="${fontName}"]::before
        {
            content: "${font}";
            font-family: "${font}", sans-serif;
        }
        .ql-font-${fontName} {
            font-family: "${font}", sans-serif;
        }`;
    });
    const node = document.createElement('style');
    node.innerHTML = fs;
    document.body.appendChild(node);
}

export function quillConfig(): any {
    //#region Font
    const Font = Quill.import('attributors/style/font');
    const fontList = fontsList.map(font => getFontName(font));
    Font.whitelist = fontList;
    generateFontStyles(fontsList);
    Quill.register(Font, true);
    //#endregion

    // Image resize
    Quill.register('modules/blotFormatter', BlotFormatter);

    // Icons
    // const icons = Quill.import('ui/icons');
    // icons['color'] = '<img src="assets/icons/font-color.svg" height="100%" />';

    return {
        modules: {
            blotFormatter: {
                modules: {
                    history: {
                        delay: 2500, userOnly: true
                    },
                    syntax: true
                }
            },
            toolbar: [
                [{ size: [] }, { font: [] } ],
                ['bold', 'italic', 'underline', 'strike'],
                [{ color: [] }, { background: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: [] }],
                ['link', 'image', 'code-block'],
                ['clean'],
            ]
        },
        theme: 'snow'
    } as QuillConfig;
}
