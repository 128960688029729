import { Component, Input, Injector, SimpleChanges } from '@angular/core';
import { ContactType } from '@app/shared/models/contact.model';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CountyDto, StateDto, StatesServiceProxy } from '@shared/service-proxies/service-proxies';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'address-section',
    templateUrl: './addressSection.component.html',
    styleUrls: ['./addressSection.compontent.less']
})
export class AddressSectionComponent extends AppComponentBase {
    @Input() addressData;
    @Input() states: StateDto[] = [];
    @Input() type: ContactType = ContactType.Customer;

    private _getCounties = new Subject<number>();

    counties$: Observable<CountyDto[]>;
    selectedState: StateDto;
    ContactType = ContactType;

    public get isCountyRequired(): boolean {
        return this.type === ContactType.Site && this.addressData.state === 'CA'
    }

    constructor(injector: Injector, private _statesServiceProxy: StatesServiceProxy) {
        super(injector);
    }

    ngOnInit() {
        this.counties$ = this._getCounties.pipe(
            switchMap(stateId => (this._statesServiceProxy.getCounties(stateId, undefined, undefined, undefined, 1000)
                .pipe(map(res => res?.items ?? [])))
            )
        )

    }

    ngOnChanges(changes: SimpleChanges) {
        const state = this.states.find(s => s.abbreviation === this.addressData.state);
        if (state) {
            this._getCounties.next(state.id);
        }
    }

    public onStateSelected() {
        const state = this.states.find(s => s.abbreviation === this.addressData.state);
        if (state) {
            this._getCounties.next(state.id);
        }
    }
}
