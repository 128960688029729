import { Component, Injector } from '@angular/core';
import {  Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'sub-header-actions-only',
    templateUrl: './sub-header-actions-only.component.html'
})
export class SubHeaderActionsOnlyComponent extends AppComponentBase {

    constructor(
        private _router: Router,
        injector: Injector
    ) {
        super(injector);
    }
}
