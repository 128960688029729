import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CustomerInvoiceEmailDocumentDto, FileDataDto, FileDatasServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { isNil } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-invoice-email-settings',
    templateUrl: './invoice-email-settings.component.html',
    styleUrls: ['./invoice-email-settings.component.less']
})
export class InvoiceEmailSettingsComponent extends AppComponentBase {
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('docPropertiesModal') docPropertiesModal: ModalDirective;

    @Input() type: InvoiceSettingType;
    @Input() uiDocuments: InvoiceEmailDocumentUi[] = [];
    @Input() toAddresses: string[] = [];
    @Input() ccAddresses: string[] = [];
    @Input() bbcAddresses: string[] = [];
    @Input() enableCustomRecipients = false;

    @Output() enableCustomRecipientsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() toAddressesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() ccAddressesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() bbcAddressesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() onRemoveFile: EventEmitter<InvoiceEmailDocumentUi> = new EventEmitter<InvoiceEmailDocumentUi>()

    InvoiceSettingType = InvoiceSettingType;

    private isValidEmail(email: string): boolean {
        const emailForm = new FormControl(email, Validators.email);
        return emailForm.valid;
    }

    constructor(injector: Injector, private _fileDataServiceProxy: FileDatasServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
    }

    public get toAddressesText(): string {
        return this.toAddresses.length ? this.toAddresses.join(', ') : 'No invoice recipients provided for client.';
    }

    public onAddToAddress(event: { originalEvent: KeyboardEvent, value: string }) {
        if (!this.isValidEmail(event.value)) {
            this.toAddresses.pop();
        } else {
            this.toAddressesChange.emit(this.toAddresses);
        }
    }

    public onAddBbcAddress(event: { originalEvent: KeyboardEvent, value: string }) {
        if (!this.isValidEmail(event.value)) {
            this.bbcAddresses.pop();
        } else {
            this.bbcAddressesChange.emit(this.bbcAddresses);
        }
    }

    public onAddCcAddress(event: { originalEvent: KeyboardEvent, value: string }) {
        if (!this.isValidEmail(event.value)) {
            this.ccAddresses.pop();
        } else {
            this.ccAddressesChange.emit(this.ccAddresses);
        }
    }

    public initialize(documents: CustomerInvoiceEmailDocumentDto[]) {
        if (isNil(documents))
            return;

        const uiDocuments = [];
        documents.forEach(doc => {
            var copy = _.cloneDeep(doc);
            uiDocuments.push({
                dto: copy,
                newFile: null
            });
        });

        this.uiDocuments = uiDocuments;
    }

    public onFileInputChange(event) {
        let newDocument = new CustomerInvoiceEmailDocumentDto();
        newDocument.fileName = this.fileInput.nativeElement?.files[0].name;
        newDocument.contentType = this.fileInput.nativeElement?.files[0].type;
        newDocument.fileDataId = null;

        this.uiDocuments.push({
            dto: newDocument,
            newFile: this.fileInput.nativeElement?.files[0]
        });
    }

    public onDownloadFileClick(uiDoc: InvoiceEmailDocumentUi) {
        window.open(`${AppConsts.remoteServiceBaseUrl}/api/DocumentFiles/Email?fileId=${uiDoc.dto.fileDataId}&isCustomerDoc=${this.type === InvoiceSettingType.Customer ? 'true' : 'false'}`, "_blank");
    }

    public onViewFileClick(uiDoc: InvoiceEmailDocumentUi) {
        window.open(`${AppConsts.remoteServiceBaseUrl}/api/DocumentFiles/Email?fileId=${uiDoc.dto.fileDataId}&inlineView=true&isCustomerDoc=${this.type === InvoiceSettingType.Customer ? 'true' : 'false'}&fileType=${uiDoc.dto.contentType}`, "_blank");
    }

    public onRemoveFileClick(doc: InvoiceEmailDocumentUi): void {
        let index = this.uiDocuments.findIndex(x => x.dto.fileName === doc.dto.fileName && x.dto.contentType === doc.dto.contentType);
        if (index > -1) {
            this.uiDocuments.splice(index, 1);
            this.onRemoveFile.emit(doc);
        }
    }
}

export enum InvoiceSettingType {
    Global,
    Customer,
    Job
}

export interface InvoiceEmailDocumentDto {
    fileDataId?: number;
    fileName?: string | undefined;
    contentType?: string | undefined;
    fileData?: FileDataDto;
    id?: number | undefined;
}

export interface InvoiceEmailDocumentUi {
    dto: InvoiceEmailDocumentDto | CustomerInvoiceEmailDocumentDto,
    newFile?: File
}

